#	# задержка при повторных выполнениях
	#
	# использование
	# 	fnDelay1 (->
	# 		my_func
				#
	# 		return
	# 	), 300
	#
	# время в милисекундах
	#
fnDelay1 = do ->
	timer = 0
	(callback, ms) ->
		clearTimeout timer
		timer = setTimeout(callback, ms)
		return
# ==========================================

# # рамку кнопок|ссылок|картинок убрать
	#
remove_border_buttons = ->
	$('input[type=submit], button, a, img').focus ->
		@blur()
		return
	#
	return
# ==========================================
